import _path from "path";
import _gracefulFs from "graceful-fs";
import _npmlog from "npmlog";
import _process from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

function _nullRequire(id) {
  var e = new Error("Cannot find module '" + id + "'");
  e.code = "MODULE_NOT_FOUND";
  throw e;
}

var process = _process;
// utilities for working with the js-registry site.
exports = RegClient;
var join = _path.join;
var fs = _gracefulFs;
var npmlog;

try {
  npmlog = _npmlog;
} catch (er) {
  npmlog = {
    error: noop,
    warn: noop,
    info: noop,
    verbose: noop,
    silly: noop,
    http: noop,
    pause: noop,
    resume: noop
  };
}

function noop() {}

function RegClient(config) {
  (this || _global).config = Object.create(config || {});
  (this || _global).config.proxy = (this || _global).config.proxy || {};

  if (!(this || _global).config.proxy.https && (this || _global).config.proxy.http) {
    (this || _global).config.proxy.https = (this || _global).config.proxy.http;
  }

  (this || _global).config.ssl = (this || _global).config.ssl || {};
  if ((this || _global).config.ssl.strict === undefined) (this || _global).config.ssl.strict = true;
  (this || _global).config.retry = (this || _global).config.retry || {};
  if (typeof (this || _global).config.retry.retries !== "number") (this || _global).config.retry.retries = 2;
  if (typeof (this || _global).config.retry.factor !== "number") (this || _global).config.retry.factor = 10;
  if (typeof (this || _global).config.retry.minTimeout !== "number") (this || _global).config.retry.minTimeout = 10000;
  if (typeof (this || _global).config.retry.maxTimeout !== "number") (this || _global).config.retry.maxTimeout = 60000;
  if (typeof (this || _global).config.maxSockets !== "number") (this || _global).config.maxSockets = 50;
  (this || _global).config.userAgent = (this || _global).config.userAgent || "node/" + process.version;
  (this || _global).config.defaultTag = (this || _global).config.defaultTag || "latest";
  (this || _global).log = (this || _global).config.log || npmlog;
  delete (this || _global).config.log;
  var client = this || _global;
  fs.readdirSync(join(new URL(import.meta.url.slice(0, import.meta.url.lastIndexOf("/"))).pathname, "lib")).forEach(function (f) {
    var entry = join(new URL(import.meta.url.slice(0, import.meta.url.lastIndexOf("/"))).pathname, "lib", f); // lib/group-name/operation.js -> client.groupName.operation

    var stat = fs.statSync(entry);

    if (stat.isDirectory()) {
      var groupName = f.replace(/-([a-z])/, dashToCamel);
      fs.readdirSync(entry).forEach(function (f) {
        if (!f.match(/\.js$/)) return;

        if (!client[groupName]) {
          // keep client.groupName.operation from stomping client.operation
          client[groupName] = Object.create(client);
        }

        var name = f.replace(/\.js$/, "").replace(/-([a-z])/, dashToCamel);
        client[groupName][name] = _nullRequire(join(entry, f));
      });
      return;
    }

    if (!f.match(/\.js$/)) return;
    var name = f.replace(/\.js$/, "").replace(/-([a-z])/, dashToCamel);
    client[name] = _nullRequire(entry);
  });
}

function dashToCamel(_, l) {
  return l.toUpperCase();
}

export default exports;